import { useTranslation } from 'react-i18next';

interface CheckboxProps {
  name: string;
  position: number;
  labelText: string;
  value?: string;
}

const Checkbox = ({ name, position, labelText, value }: CheckboxProps) => {
  const { t } = useTranslation();

  return (
    <div className={`flex items-center  gap-4 max-w-[360px]`}>
      <div className="relative flex text-white my-3">
        <input
          type="checkbox"
          id={position.toString()}
          className="appearance-none w-6 h-6 border-3 border-brand-green checked:border-[5px] focus:outline-none cursor-pointer z-10 peer"
          name={name}
          value={value ?? ''}
        />
        <svg
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4 h-4 hidden peer-checked:block peer-checked: bg-brand-green"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="square"
          strokeLinejoin="miter"
        >
          <polyline points="20 6 10 17 4 12"></polyline>
        </svg>
      </div>
      <label
        htmlFor={name.toString()}
        className="text-black align-middle leading-4"
      >
        {labelText}
      </label>
    </div>
  );
};

export default Checkbox;
